import React, {  useEffect, useState } from 'react';
import { Container, ToggleButton, CardGroup, Card, Form, ListGroup, Row, Col, Button, ToastContainer, Toast, FormGroup } from 'react-bootstrap';
import { callHubexApi } from '../../assets/js/services';


function ThirdSection({topSec,secSec,segProt}){

    const [radioValue02, setRadioValue02] = useState('0');
    const [condicaoInicial,setCondicaoInicial] = useState({entrada: "0",metal: "0",alteracao: "0"});
    const [condicaoFinal,setCondicaoFinal] = useState({entrada: "0",metal: "0",alteracao: "0"});
    const [passInvalid,setPassInvalid] = useState({isInvalid: false,message: "Senha de salvamento é obrigatória"});
    const [formValues,setFormValues] = useState({produto1:"",produto2:"",observacao:"",pass:""});
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [toastMsg, setToastMsg] = useState({titulo: "",message: "",bg: ""});
    const [acesso,setAcesso] = useState("0");
    

    const verCaondicaoDigitada = (metodo,cod,e) => {
        if(e.currentTarget.value > 5 || e.currentTarget.value.includes("-")){
            e.currentTarget.value = 5;
        }
        switch (cod) {
            case 0:
                let upEntrada = {entrada: e.currentTarget.value};
                metodo(obj => ({...obj, ...upEntrada}));
                break;
            case 1:
                let upMetal = {metal: e.currentTarget.value};
                metodo(obj => ({...obj,...upMetal}));
                break;
            case 2:
                let upAltera = {alteracao: e.currentTarget.value};
                metodo(obj => ({...obj,...upAltera}));
                break;    
            default:
                break;
        }
    };

    const radios = [
        { name: 'Transparente', value: '1' },
        { name: 'Colorido', value: '2' },
        { name: 'Reflexivo', value: '3' },
        { name: 'Não Sei', value: '4' },
    ];

    const handleRegister = async (dataPost) => {
    
        await callHubexApi(dataPost)
            .then(data => {
                setLoading(false);
                if(!data)
                {
                    setShow(true);
                    setToastMsg({titulo: "Aviso",message: "Ocorreu um erro, Sem dados !", bg: "warning"});
                    return false;
                }
    
                if(data.success)
                {                   
                    scrollToTop();
                    setShow(true);
                    setToastMsg({titulo: "Sucesso",message: "Salvo com sucesso !!", bg: "success"});

                    setFormValues({produto1:"",produto2:"",observacao: formValues.observacao,pass: formValues.pass});
                }
                else
                {
                    setShow(true);
                    setToastMsg({titulo: "Erro",message: data.messages[0] ? data.messages[0] : "Ocorreu um erro , Tente novamente !", bg: "danger"});
                }
    
    
            })
            .catch(error => {
                setLoading(false);
    
                setShow(true);
                setToastMsg({titulo: "Erro",message: "Ocorreu um erro , Tente novamente !", bg: "danger"});
            })
    };
    
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function errorNoInfo(){
        setShow(true);
        setToastMsg({titulo: "Aviso",message: "Alguma informação não foi selecionada !", bg: "warning"});
    }

    const salvar = (formVal) => {

        if(secSec === '6'){
            if(topSec === '0' || segProt === '0' || acesso === '0'){
                errorNoInfo();
                return;
            }
        } else {
            if(topSec === '0' || secSec === '0' || radioValue02 === '0' || acesso === '0'){
                errorNoInfo();
                return;
            }
        }

        if(formVal.pass === ""){
            setPassInvalid({isInvalid: true,message: "Senha de salvamento é obrigatória"});
            return;
        }

        setLoading(true);
        
        let dataPost = null
    
        if(topSec === '1')
        {
            dataPost = JSON.stringify({
                tr: 'cad-opc-1',
                opc1: secSec, // são as var states
                opc2: radioValue02,
                entLuz1: condicaoInicial.entrada,
                usoMetal1: condicaoInicial.metal,
                alteracao1: condicaoInicial.alteracao,
                prod1: formVal.produto1,
                prod2: formVal.produto2,
                obs: formVal.observacao,
                opc3: acesso,
                segprot: segProt,
                pwcad: formVal.pass
            })
        }
    
        if(topSec === '2')
        {
            dataPost = JSON.stringify({
                tr: 'cad-opc-2',
                opc1: secSec, // são as var states
                opc2: radioValue02,
                entLuz1: condicaoInicial.entrada,
                usoMetal1: condicaoInicial.metal,
                alteracao1: condicaoInicial.alteracao,
                entLuz2: condicaoFinal.entrada,
                usoMetal2: condicaoFinal.metal,
                alteracao2: condicaoFinal.alteracao,
                prod1: formVal.produto1,
                prod2: formVal.produto2,
                obs: formVal.observacao,
                opc3: acesso,
                segprot: segProt,
                pwcad: formVal.pass
            })
        }

        handleRegister(dataPost);
    };

    useEffect(() => {
        if(secSec === '6') setRadioValue02('0');
    },[secSec]);

    return(
        <div>
            <Container className='mt-3 mb-5' fluid>
                <h4 className="mb-3">Qual / Aplicação</h4>
                {secSec === '6' ? (<></>) : (<>
                    <CardGroup className="" >
                        {radios.map((radio, idx) => (
                            <Card className="p-2" bg='light' key={idx}>
                                <ToggleButton
                                    style= {{ fontSize: "1.1rem", fontWeight: "bolder"}}
                                    className="m-2"
                                    id={`radioThi-${idx}`}
                                    type="radio"
                                    variant='outline-success'
                                    name="radioThi"
                                    value={radio.value}
                                    checked={radioValue02 === radio.value}
                                    onChange={(e) => setRadioValue02(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            </Card>
                        ))}
                    </CardGroup>
                </>)}
                <FormGroup >                    
                    <div variant="success" className="mt-3 mb-4 p-3" style= {{ backgroundColor: "#F8F9FA", borderRadius: "8px",fontSize: "1rem", fontWeight: "bolder"  }} >
                        <Row>
                            <Col sm>
                                <Form.Check
                                    isValid
                                    inline
                                    label="Acesso aos vidros para instalação da
                                    película pelo lado interno"
                                    name="grupoAcesso"
                                    type="radio"
                                    id="comAcesso"
                                    value="1"
                                    onChange={(e) => setAcesso(e.currentTarget.value)}
                                />
                            </Col>
                            <Col sm>
                                <Form.Check
                                    isValid
                                    inline
                                    label="Sem acesso interno aos vidros, a instalação
                                    tem que ser externa"
                                    name="grupoAcesso"
                                    type="radio"
                                    id="semAcesso"
                                    value="2"
                                    onChange={(e) => setAcesso(e.currentTarget.value)}
                                />
                            </Col>
                        </Row>
                    </div>                   
                </FormGroup>
                <div >
                    <div className="p-2 mt-3 mb-3" style={{ backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
                        <h4>Condição</h4>
                    </div>
                    <Row md={3}>
                        <Col  md>
                            <h5>Inicial</h5>
                            <ListGroup horizontal  style= {{ fontSize: "1.0rem", fontWeight: "bolder"}}>
                                <ListGroup.Item>Entrada de Luz</ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Range max="5" value={condicaoInicial.entrada} onChange={(e) => {
                                        let upEntrada = {entrada: e.currentTarget.value};
                                        setCondicaoInicial(obj => ({...obj, ...upEntrada}));}}/>
                                </ListGroup.Item>
                                <ListGroup.Item><Form.Control min="0" max="5" type="number" value={condicaoInicial.entrada} onChange={(e) => verCaondicaoDigitada(setCondicaoInicial,0,e)}/> </ListGroup.Item>                      
                            </ListGroup>
                            <ListGroup horizontal  style= {{fontSize: "1.0rem", fontWeight: "bolder", padding: "10px 0px" }}>
                                <ListGroup.Item>Uso de Metal<br/>
                                <span>( Metalizado )</span>
                                </ListGroup.Item>
                                <ListGroup.Item style= {{ justifyItems: "center" }}>
                                    <Form.Range max="5" value={condicaoInicial.metal} onChange={(e) => {
                                        let upMetal= {metal: e.currentTarget.value};
                                        setCondicaoInicial(obj => ({...obj, ...upMetal}));}}/>
                                </ListGroup.Item>
                                <ListGroup.Item><Form.Control min="0" max="5" type="number" value={condicaoInicial.metal} onChange={(e) => verCaondicaoDigitada(setCondicaoInicial,1,e)}/> </ListGroup.Item>                      
                            </ListGroup>
                            <ListGroup horizontal  style= {{ fontSize: "1.0rem", fontWeight: "bolder" }}>
                                <ListGroup.Item>Alteração</ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Range max="5" value={condicaoInicial.alteracao} onChange={(e) => {
                                        let upAltera= {alteracao: e.currentTarget.value};
                                        setCondicaoInicial(obj => ({...obj, ...upAltera}));}}/>
                                </ListGroup.Item>
                                <ListGroup.Item><Form.Control min="0" max="5"  type="number" value={condicaoInicial.alteracao} onChange={(e) => verCaondicaoDigitada(setCondicaoInicial,2,e)}/></ListGroup.Item>                      
                            </ListGroup>
                        </Col>
                        <Col  md>
                            {topSec === '2' ? ( 
                                <>
                                    <h5>Final</h5>
                                    <ListGroup horizontal  style= {{ fontSize: "1.0rem", fontWeight: "bolder"}}>
                                        <ListGroup.Item>Entrada de Luz</ListGroup.Item>
                                        <ListGroup.Item>
                                            <Form.Range max="5" value={condicaoFinal.entrada} onChange={(e) => {
                                                let upEntrada = {entrada: e.currentTarget.value};
                                                setCondicaoFinal(obj => ({...obj, ...upEntrada}));
                                            }}/>
                                        </ListGroup.Item>
                                        <ListGroup.Item><Form.Control min="0" max="5" type="number" value={condicaoFinal.entrada} onChange={(e) => verCaondicaoDigitada(setCondicaoFinal,0,e)}/> </ListGroup.Item>                      
                                    </ListGroup>
                                    <ListGroup horizontal  style= {{fontSize: "1.0rem", fontWeight: "bolder", padding: "10px 0px" }}>
                                        <ListGroup.Item>Uso de Metal<br/>
                                        <span>( Metalizado )</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item style= {{ justifyItems: "center" }}>
                                            <Form.Range max="5" value={condicaoFinal.metal} onChange={(e) => {
                                                let upMetal= {metal: e.currentTarget.value};
                                                setCondicaoFinal(obj => ({...obj, ...upMetal}));
                                            }}/>
                                        </ListGroup.Item>
                                        <ListGroup.Item><Form.Control min="0" max="5" type="number" value={condicaoFinal.metal} onChange={(e) => verCaondicaoDigitada(setCondicaoFinal,1,e)}/> </ListGroup.Item>                      
                                    </ListGroup>
                                    <ListGroup horizontal  style= {{ fontSize: "1.0rem", fontWeight: "bolder"}}>
                                        <ListGroup.Item>Alteração</ListGroup.Item>
                                        <ListGroup.Item>
                                            <Form.Range max="5" value={condicaoFinal.alteracao} onChange={(e) => {
                                                let upAltera= {alteracao: e.currentTarget.value};
                                                setCondicaoFinal(obj => ({...obj, ...upAltera}));
                                            }}/>
                                        </ListGroup.Item>
                                        <ListGroup.Item><Form.Control min="0" max="5"  type="number" value={condicaoFinal.alteracao} onChange={(e) => verCaondicaoDigitada(setCondicaoFinal,2,e)}/></ListGroup.Item>                      
                                    </ListGroup>
                                </>
                             ) : ( <></> ) }
                        </Col>
                        <Col md>
                        <Form style= {{ textAlign: "start", fontWeight: "bold" }}>
                            <Form.Group className="mb-1" controlId="textoProduto1">
                                <Form.Label>Produto 1 </Form.Label>
                                <Form.Control type="text" placeholder="Produto 1" value={formValues.produto1} onChange={(e) => {
                                    let upProd1= {produto1: e.currentTarget.value};
                                    setFormValues(obj => ({...obj, ...upProd1}));
                                }}/>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="textoProduto2">
                                <Form.Label>Produto 2</Form.Label>
                                <Form.Control type="text" placeholder="Produto 2" value={formValues.produto2} onChange={(e) => {
                                    let upProd2= {produto2: e.currentTarget.value};
                                    setFormValues(obj => ({...obj, ...upProd2}));
                                }}/>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="textoDescricao">
                                <Form.Label>Observação</Form.Label>
                                <Form.Control as="textarea" rows={4} value={formValues.observacao} onChange={(e) => {
                                    let obs= {observacao: e.currentTarget.value};
                                    setFormValues(obj => ({...obj, ...obs}));
                                }}/>
                            </Form.Group>
                            <Row className="align-items-center mt-3">
                                <Col xs={8}>                                    
                                    <Form.Control
                                        isInvalid={passInvalid.isInvalid}
                                        className="mb-2"
                                        id="textoPass"
                                        type= "password"
                                        value={formValues.pass}
                                        placeholder="Sua senha de salvamento"
                                        onChange={(e) => {
                                            if(passInvalid.isInvalid){
                                                let upPass = {isInvalid: false};
                                                setPassInvalid(obj => ({...obj, ...upPass}));
                                            }
                                            let ps = {pass: e.currentTarget.value};
                                            setFormValues(obj => ({...obj, ...ps}));
                                        }}
                                    /> 
                                    <Form.Control.Feedback type="invalid">
                                        {passInvalid.message}
                                    </Form.Control.Feedback> 
                                </Col>
                                <Col xs={2}>
                                    <Button type="button" size="lg" className="mb-2" disabled={isLoading} onClick={() => salvar(formValues)}>
                                        {isLoading ? 'Salvando... ' : 'Salvar'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        </Col>
                        <ToastContainer className="p-3" position='bottom-center'>
                            <Toast bg={toastMsg.bg} onClose={() => setShow(false)} show={show} delay={10000} autohide>
                                <Toast.Header closeButton={true}>
                                <strong className="me-auto">{toastMsg.titulo}</strong>
                                <small> Hubex</small>
                                </Toast.Header>
                                <Toast.Body style= {{fontWeight: "bold" }}>{toastMsg.message}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ThirdSection;

