import React, { useState } from 'react';
import { Container, ToggleButton, Card, Row, Col } from 'react-bootstrap';

function TopSection({topToApp}){

    const [radioValue, setRadioValue] = useState('0');

    const radios = [
        { name: 'Configuração Inicial', value: '1' },
        { name: 'Configuração Final', value: '2' }
    ];

    return(
        <div>
            <Container >
                <Row xs={1} md={2} className="g-5">
                    {radios.map((radio, idx) => (
                        <Col key={idx}>
                            <Card className="p-2 mt-3 " bg='light'>
                                <ToggleButton
                                    style= {{ fontSize: "1.1rem", fontWeight: "bold" }}
                                    className="m-2"
                                    id={`radioTop-${idx}`}
                                    type="radio"
                                    variant='outline-success'
                                    name="radioTop"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => {setRadioValue(e.currentTarget.value); topToApp(e.currentTarget.value);}}
                                >
                                    {radio.name}
                                </ToggleButton>
                                
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default TopSection;