import React, { useState } from 'react';
import { Container, ToggleButton, CardGroup, Card, FormGroup, Row, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function SecondSection({secondToApp,secondToSegProt}){

    const [radioValue, setRadioValue] = useState('0');

    const radios = [
        { name: 'Redução de Calor', value: '1' },
        { name: 'Calor + Transp', value: '2' },
        { name: 'Privacidade', value: '3' },
        { name: 'Desbotamento', value: '4' },
        { name: 'Redução de Claridade', value: '5' },
        { name: 'Segurança e Proteção', value: '6' },
    ];

    return(
        <div className="justify-content-md-center">
            <Container fluid>
                <h4 className="mb-3">Qual é a necessidade</h4>
                <CardGroup className="" >
                    {radios.map((radio, idx) => (
                        <Card className="p-2" bg='light' key={idx}>
                            <ToggleButton
                                style= {{ fontSize: "1.1rem", fontWeight: "bold" }}
                                className="m-2"
                                id={`radioSec-${idx}`}
                                type="radio"
                                variant='outline-success'
                                name="radioSec"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => {
                                    setRadioValue(e.currentTarget.value); 
                                    secondToApp(e.currentTarget.value);
                                }}
                            >
                                {radio.name}
                            </ToggleButton>
                        </Card>
                    ))}
                </CardGroup>
                {radioValue === '6' ? (<>
                    <FormGroup >                    
                    <div className="mt-3 mb-4 p-3" 
                        style= {{width: "50%", backgroundColor: "#F8F9FA", borderRadius: "8px",fontSize: "1rem", fontWeight: "bolder" ,margin: "0 auto"}} >
                        <Row>
                            <Col sm>
                                <Form.Check
                                    isValid
                                    inline
                                    label="Temperado"
                                    name="grupoSegProt"
                                    type="radio"
                                    id="radioTemp"
                                    value="1"
                                    onChange={(e) => secondToSegProt(e.currentTarget.value)}
                                />
                            </Col>
                            <Col sm>
                                <Form.Check
                                    isValid
                                    inline
                                    label="Laminado"
                                    name="grupoSegProt"
                                    type="radio"
                                    id="radioLami"
                                    value="2"
                                    onChange={(e) => secondToSegProt(e.currentTarget.value)}
                                />
                            </Col>
                        </Row>
                    </div>                   
                </FormGroup>
                </>) : (<></>)}
            </Container>
        </div>
    )
}

export default SecondSection;