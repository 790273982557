import axios from "axios";
import { API_URL } from "./api-url";

export const callHubexApi = async (payload) => {

    var axiosCreate = axios.create(
        {
            validateStatus: function (status) {
                return status >= 200;
            }
        }
    )

    var config = {
        method: 'post',
        url: API_URL.URL + 'simule-projeto-cad',
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };

    try{
        let data = await axiosCreate(config).then(({data}) => data)
        return data
    }
    catch (err) {
        return false
    }
}