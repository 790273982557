import {useEffect, useState} from 'react';
import {Modal, Table, Button, Spinner, ToastContainer, Toast} from "react-bootstrap";
import {callHubexApi} from "../assets/js/services";


const ListaInicial = () => {

    const [loader, setLoader] = useState(false)
    const [items, setItems] = useState([{}])
    const [contentLoaded, setContentLoaded] = useState(false)
    const [loadError, setLoadError] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [currentItem, setCurrentItem] = useState(null)

    const [show, setShow] = useState(false);
    const [toastMsg, setToastMsg] = useState({titulo: "",message: "",bg: ""});

    const [showModal, setShowModal] = useState(false)



    const [pw, setPw] = useState('');

    useEffect(() => {

        if(!contentLoaded)
        {
            setLoader(true)

            var dataPost = JSON.stringify({
                "tr": "lista-opc-1"
            });

            callHubexApi(dataPost)
                .then(data => {

                    setLoader(false)
                    setContentLoaded(true)

                    if(!data)
                    {
                        setLoadError(true);
                        //setMsgError("An error has occurred, please reload the page");
                        setToastMsg({titulo: "Aviso",message: "Ocorreu um erro, Sem dados !", bg: "warning"});
                        return false;
                    }

                    if(data.success)
                    {
                        console.log(data)
                        setItems(data.data.lista)
                    }
                    else
                    {
                        setLoadError(true);
                        //setMsgError("An error has occurred, please reload the page");
                        setToastMsg({titulo: "Erro",message: data.messages[0] ? data.messages[0] : "Ocorreu um erro , Tente novamente !", bg: "danger"});
                        return false;
                    }



                })
                .catch(erro => {
                    setContentLoaded(true);
                    setLoader(false)
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Erro",message: "Ocorreu um erro , Tente novamente !", bg: "danger"});
                    return false;
            })


        }

    }, [])

    const handleReloadPage = async () => {
        setLoader(true)

        var dataPost = JSON.stringify({
            "tr": "lista-opc-1"
        });

        await callHubexApi(dataPost)
            .then(data => {

                setLoader(false)
                setContentLoaded(true)

                if(!data)
                {
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Aviso",message: "Ocorreu um erro, Sem dados !", bg: "warning"});
                    return false;
                }

                if(data.success)
                {
                    setItems(data.data.lista)
                }
                else
                {
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Erro",message: data.messages[0] ? data.messages[0] : "Ocorreu um erro , Tente novamente !", bg: "danger"});
                    return false;
                }



            })
            .catch(erro => {
                setContentLoaded(true);
                setLoader(false)
                setLoadError(true);
                //setMsgError("An error has occurred, please reload the page");
                setToastMsg({titulo: "Erro",message: "Ocorreu um erro , Tente novamente !", bg: "danger"});
                return false;
            })
    }

    const handleCurrentItem = (item) => {
        setCurrentItem(item);
        setShowModal(true)
        console.log(item)
    }

    const handleCloseModal = () => {
        setCurrentItem(null)
        setShowModal(false)
    }

    const handleDelItem = async (itemToDel) => {

        setLoader(true)

        var dataPost = JSON.stringify({
            "tr": "del-opc-1",
            "id": itemToDel.id,
            "pwcad": pw
        });

        await callHubexApi(dataPost)
            .then(data => {

                setLoader(false)

                if(!data)
                {
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Aviso",message: "Ocorreu um erro, Sem dados !", bg: "warning"});
                    return false;
                }

                if(data.success)
                {
                    const itemsAtuais = items
                    const newItems = itemsAtuais.filter(item => item.id !== itemToDel.id)

                    setItems(newItems)
                }
                else
                {
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Erro",message: data.messages[0] ? data.messages[0] : "Ocorreu um erro , Tente novamente !", bg: "danger"});
                    return false;
                }



            })
            .catch(erro => {
                setContentLoaded(true);
                setLoader(false)
                setLoadError(true);
                setToastMsg({titulo: "Erro",message: "Ocorreu um erro , Tente novamente !", bg: "danger"});
                return false;
            })




    }

    const handleEditItem = async () => {

        setLoader(true)

        var dataPost = JSON.stringify({
            "tr": "edit-opc-1",
            "id": currentItem.id,
            "opc1": currentItem.opc1Num,
            "opc2": currentItem.opc2Num,
            "entLuz1": currentItem.entLuz1,
            "usoMetal1": currentItem.usoMetal1,
            "alteracao1": currentItem.alteracao1,
            "prod1": currentItem.prod1,
            "prod2": currentItem.prod2,
            "obs": currentItem.obs,
            "pwcad": pw,
            "opc3": currentItem.opc3Num,
            "segprot": currentItem.segprotNum,
        });

        await callHubexApi(dataPost)
            .then(data => {

                setLoader(false)

                if(!data)
                {
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Aviso",message: "Ocorreu um erro, Sem dados !", bg: "warning"});
                    return false;
                }

                if(data.success)
                {
                    const returnedItem = data.data.novoItem
                    const itemsAtuais = items
                    const exist = itemsAtuais.filter(item => item.id === returnedItem.id)

                    if(exist)
                    {
                        const oldItems = items;
                        const newItems = oldItems.map(item => {
                            if(item.id === returnedItem.id)
                            {
                                return returnedItem
                            }
                            return item
                        })

                        console.log("NOVO ITEM: ", returnedItem)

                        setItems(newItems)
                        handleCloseModal()
                    }
                }
                else
                {
                    setLoadError(true);
                    //setMsgError("An error has occurred, please reload the page");
                    setToastMsg({titulo: "Erro",message: data.messages[0] ? data.messages[0] : "Ocorreu um erro , Tente novamente !", bg: "danger"});
                    return false;
                }



            })
            .catch(erro => {
                setContentLoaded(true);
                setLoader(false)
                setLoadError(true);
                setToastMsg({titulo: "Erro",message: "Ocorreu um erro , Tente novamente !", bg: "danger"});
                return false;
            })




    }


    const handleOnChangePw = event => {
        const { name, value } = event.target;
        setPw(value)
    }

    const handleChangeCurrent = event => {
        const { name, value } = event.target;
        setCurrentItem({...currentItem,[name]: value});

        console.log("NAME", name)
        console.log("VALOR", value)

    }



    return (
        <>

            {loader &&
                <div className="div-load-banner" style={{height: '100%', position: 'absolute', zIndex: '9'}}>
                    <div className="wrapper" >
                        <Spinner animation="border" />
                    </div>
                </div>
            }

            {contentLoaded ?
                <div className='container-fluid' style={{marginTop: '40px'}}>
                    <div className='row'>
                        <div className="col-2">
                            <input placeholder="Sua senha" type="password" id="textoPass"
                                   className="mb-2 form-control" value={pw}
                                   onChange={handleOnChangePw}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12">

                            <h4>Listando Cadastro Inicial</h4>
                            <Button variant="secondary"
                                    size="sm"
                                    onClick={() => handleReloadPage()}
                            >
                                atualizar
                            </Button>

                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Necessidade</th>
                                            <th>Aplicação</th>
                                            <th>Instalação</th>
                                            <th>Ent. de Luz</th>
                                            <th>Uso de Metal</th>
                                            <th>Alt.</th>
                                            <th>Prod. 1</th>
                                            <th>Prod. 2</th>
                                            <th style={{width: '250px'}}>Obs.</th>
                                            <th>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {items && items.length > 0 ?
                                        items.map((item, index) => {

                                            return(
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.opc1}</td>
                                                    <td>{item.opc2}</td>
                                                    <td>{item.opc3}</td>
                                                    <td>{item.entLuz1}</td>
                                                    <td>{item.usoMetal1}</td>
                                                    <td>{item.alteracao1}</td>
                                                    <td>{item.prod1}</td>
                                                    <td>{item.prod2}</td>
                                                    <td>{item.obs}</td>
                                                    <td>
                                                        <Button variant='primary'
                                                                onClick={() => handleCurrentItem(item)}
                                                        >
                                                            Editar
                                                        </Button>
                                                        { ' ' }
                                                        <Button variant='danger'
                                                                onClick={() => handleDelItem(item)}
                                                        >
                                                            Apagar
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )

                                        })

                                        : null
                                    }
                                    </tbody>

                            </Table>
                        </div>
                    </div>
                </div>

                : null
            }


            {currentItem !== null ?

                <Modal show={showModal} onHide={handleCloseModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar ID: <b>{currentItem.id}</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='row mb-2'>
                            <div className='col-12 col-md-4'>
                                <label className="form-label" htmlFor="opc1Num">
                                    Necessidade
                                </label>
                                <select className="form-select"
                                        aria-label="Default select example"
                                        id='opc1Num'
                                        name='opc1Num'
                                        onChange={handleChangeCurrent}
                                >
                                    <option value="1" selected={currentItem.opc1Num === 1} >Redução de Calor</option>
                                    <option value="2" selected={currentItem.opc1Num === 2}>Calor + Transp</option>
                                    <option value="3" selected={currentItem.opc1Num === 3}>Privacidade</option>
                                    <option value="4" selected={currentItem.opc1Num === 4}>Desbotamento</option>
                                    <option value="5" selected={currentItem.opc1Num === 5}>Red. de Claridade</option>
                                    <option value="6" selected={currentItem.opc1Num === 6}>Seg. e Prot</option>
                                </select>

                            </div>

                            <div className='col-12 col-md-4'>
                                <label className="form-label" htmlFor="opc2Num">
                                    Aplicação
                                </label>
                                <select className="form-select"
                                        aria-label="Default select example"
                                        id='opc2Num'
                                        name='opc2Num'
                                        onChange={handleChangeCurrent}
                                >
                                    <option value="1" selected={currentItem.opc2Num === 1}>Transparente</option>
                                    <option value="2" selected={currentItem.opc2Num === 2}>Colorido</option>
                                    <option value="3" selected={currentItem.opc2Num === 3}>Reflexivo</option>
                                    <option value="4" selected={currentItem.opc2Num === 4}>Não Sei</option>
                                </select>

                            </div>

                            <div className='col-12 col-md-4'>
                                <label className="form-label" htmlFor="opc3Num">
                                    Instalação
                                </label>
                                <select className="form-select"
                                        aria-label="Default select example"
                                        id='opc3Num'
                                        name='opc3Num'
                                        onChange={handleChangeCurrent}
                                >
                                    <option value="1" selected={currentItem.opc3Num === 1}>Interna</option>
                                    <option value="2" selected={currentItem.opc3Num === 2}>Externa</option>
                                </select>

                            </div>
                        </div>

                        <div className='row mb-2'>
                            <div className='col-12 col-md-6'>
                            <label className="form-label" htmlFor="segprot">
                                    Segurança e Proteção
                                </label>
                                <select className="form-select"
                                        aria-label="Default select example"
                                        id='segprot'
                                        name='segprot'
                                        onChange={handleChangeCurrent}
                                >
                                    <option value="1" selected={currentItem.segprotNum === 1}>Temperado</option>
                                    <option value="2" selected={currentItem.segprotNum === 2}>Laminado</option>
                                </select>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-1">
                                    <label className="form-label" htmlFor="prod1">
                                        Produto 1
                                    </label>
                                    <input placeholder=""
                                           type="text"
                                           id="prod1"
                                           name='prod1'
                                           className="form-control"
                                           value={currentItem.prod1}
                                           onChange={handleChangeCurrent}
                                    />
                                </div>

                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-1">
                                    <label className="form-label" htmlFor="prod2">
                                        Produto 2
                                    </label>
                                    <input placeholder=""
                                           type="text"
                                           id="prod2"
                                           name='prod2'
                                           className="form-control"
                                           value={currentItem.prod2}
                                           onChange={handleChangeCurrent}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='row mb-2'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-1">
                                    <label className="form-label"
                                           htmlFor="obs">
                                        Observação
                                    </label>
                                    <textarea rows="4"
                                              id="obs"
                                              name="obs"
                                              className="form-control"
                                              value={currentItem.obs}
                                              onChange={handleChangeCurrent}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className='col-12 col-md-4'>
                                <div className="mb-1">
                                    <label className="form-label" htmlFor="entLuz1">
                                        Entrada de Luz
                                    </label>
                                    <input placeholder=""
                                           type="number"
                                           id="entLuz1"
                                           name='entLuz1'
                                           className="form-control"
                                           value={currentItem.entLuz1}
                                           onChange={handleChangeCurrent}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <div className="mb-1">
                                    <label className="form-label" htmlFor="usoMetal1">
                                        Uso de Metal
                                    </label>
                                    <input placeholder=""
                                           type="number"
                                           id="usoMetal1"
                                           name='usoMetal1'
                                           className="form-control"
                                           value={currentItem.usoMetal1}
                                           onChange={handleChangeCurrent}
                                    />
                                </div>
                            </div>

                            <div className='col-12 col-md-4'>
                                <div className="mb-1">
                                    <label className="form-label" htmlFor="alteracao1">
                                        Alteração
                                    </label>
                                    <input placeholder=""
                                           type="number"
                                           id="alteracao1"
                                           name='alteracao1'
                                           className="form-control"
                                           value={currentItem.alteracao1}
                                           onChange={handleChangeCurrent}
                                    />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleEditItem}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>

                : null
            }



            <ToastContainer className="p-3" position='bottom-center'>
                <Toast bg={toastMsg.bg} onClose={() => setLoadError(false)} show={loadError} delay={10000} autohide>
                    <Toast.Header closeButton={true}>
                        <strong className="me-auto">{toastMsg.titulo}</strong>
                        <small> Hubex</small>
                    </Toast.Header>
                    <Toast.Body style= {{fontWeight: "bold" }}>{toastMsg.message}</Toast.Body>
                </Toast>
            </ToastContainer>


        </>
    );

}

export default ListaInicial;
