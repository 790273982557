
import { useState } from 'react';
import './App.css';
import SecondSection from './sections/segunda-sec/second';
import ThirdSection from './sections/terceira-sec/third';
import TopSection from './sections/top-sec/top-section';

function App() {
  const [topSec, setTopSec] = useState('0');
  const [secSec,setSecSec] = useState('0');
  const [segProt,setSegProt] = useState('0');

  const topToApp = (num) => {
    setTopSec(num);
  };

  const secondToApp = (num) => {
    setSecSec(num);
    if(num !== '6'){
      setSegProt('0');
    }
  };

  const secondToSegProt = (num) => {
    setSegProt(num);
  };

  return (
    <div className="App">
      <header className="">
        <TopSection topToApp={topToApp}/>
        <div className='mt-3 mb-3' style={{ backgroundColor: "gray", height: "2px" }}></div>
        <SecondSection secondToApp={secondToApp} secondToSegProt={secondToSegProt} />
        <div className='mt-3 mb-3' style={{ backgroundColor: "gray", height: "2px" }}></div>
        <ThirdSection topSec={topSec} secSec={secSec} segProt={segProt} />
      </header>
    </div>
  );
}

export default App;
